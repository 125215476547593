import Vue from 'vue';
import { IfacConferencesHelpers } from '@ifac/ui';

export const gridDefaultColDef = {
  filter: 'agSetColumnFilter',
  resizable: true,
  sortable: true,
};

export const gridColumns = [
  {
    headerName: 'Conference Name',
    field: 'title',
    minWidth: 350,
    sort: 'asc',
    valueGetter: (params) => IfacConferencesHelpers.getConferenceName(params.data),
  },
  {
    headerName: 'Schedule',
    field: 'startsAt',
    flex: 1,
    minWidth: 200,
    valueGetter: (params) => {
      const startsAt = Vue.filter('formatDateTime')(params.data.startsAt);
      const endsAt = Vue.filter('formatDateTime')(params.data.endsAt);
      return `${startsAt} - ${endsAt}`;
    },
  },
  {
    headerName: 'Location',
    field: 'city',
    maxWidth: 200,
    valueGetter: (params) => `${params.data.city} - ${params.data.country?.name}`,
  },
  {
    headerName: 'Sponsoring TCs',
    field: 'title',
    minWidth: 200,
    valueGetter: (params) => {
      const tcs = [];
      params.data.tcs.forEach((tc) => {
        tcs.push(`${tc.ccNumber}.${tc.number}`);
      });
      return tcs.join(', ');
    },
  },
  {
    headerName: 'Affiliate',
    field: 'affiliate',
    maxWidth: 300,
    valueGetter: (params) => {
      let name = `${params.data.affiliate.name} ${params.data.affiliate.surname}`;

      if (params.data.affiliate.is_deceased) {
        name += ' ✝';
      }

      return name;
    },
  },
  {
    headerName: 'Status',
    field: 'status',
    maxWidth: 150,
    valueGetter: (params) => Vue.filter('ucfirst')(params.data.status),
  },
];
