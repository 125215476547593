<template>
  <div>
    <h2>Conferences</h2>
    <div class="row">
      <div class="col-6">
        <search-filter
          :showSearch="true"
          v-model="query.search"
        >
        </search-filter>
      </div>
      <div class="col-6 d-flex justify-content-end search-actions">
        <b-button-group>
          <b-button
            :class="setStatusClass(open.status)"
            @click="setStatus(open.status)"
          >
            {{ open.name }}
          </b-button>
          <b-button
            :class="setStatusClass(unsubmitted.status)"
            @click="setStatus(unsubmitted.status)"
          >
            {{ unsubmitted.name }}
          </b-button>
          <b-button
            :class="setStatusClass(historic.status)"
            @click="setStatus(historic.status)"
          >
            {{ historic.name }}
          </b-button>
        </b-button-group>
      </div>
    </div>
    <results-info
      :count="count"
      :selectedCount="selectedCount"
      resultsDescriptor="Conference"
    />
    <ag-grid-vue
      pagination
      paginationAutoPageSize
      class="ag-theme-alpine"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :rowData="rowData"
      :gridOptions="gridOptions"
      @gridReady="onGridReady"
      @cellClicked="onCellClicked"
      rowSelection="single"
      domLayout="autoHeight"
    ></ag-grid-vue>
  </div>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue';
import { ConferenceStatuses } from '@ifac/ui';
import SearchFilter from '@/views/components/grids/SearchFilter.vue';
import ResultsInfo from '@/views/components/grids/ResultsInfo.vue';
import Conferences from '@/services/Api/Conferences';
import GridMixin from '@/views/components/grids/GridMixin';
import {
  gridColumns,
  gridDefaultColDef,
} from '@/views/components/grids/conferences/ConferencesGrid';

const { open, unsubmitted, historic } = ConferenceStatuses;

export default {
  name: 'Conferences',
  components: {
    AgGridVue,
    SearchFilter,
    ResultsInfo,
  },
  mixins: [GridMixin],
  created() {
    this.filters.status = open?.status;
  },
  data() {
    return {
      rowData: [],
      defaultColDef: gridDefaultColDef,
      columnDefs: gridColumns,
      filters: {
        status: null,
      },
    };
  },
  computed: {
    open() {
      return open;
    },
    unsubmitted() {
      return unsubmitted;
    },
    historic() {
      return historic;
    },
  },
  methods: {
    postGridReady() {
      this.refresh();
    },
    async refresh() {
      this.refreshing = true;

      try {
        const {
          data: {
            data: { items },
          },
        } = await Conferences.all(
          1,
          9999,
          null,
          null,
          'desc',
          this.filters,
        );
        this.rowData = items;
      } catch (e) {
        console.error(e);
        this.$snack.success({
          text: 'There was a problem with the server.',
        });
      } finally {
        this.refreshing = false;
      }
    },
    setStatus(status) {
      if (status === this.filters.status) {
        return;
      }

      this.filters.status = status;
      this.refresh();
    },
    setStatusClass(status) {
      return this.filters.status === status ? 'active' : null;
    },
    onCellClicked({ data }) {
      this.$router.push(
        {
          name: 'ConferencesOverview',
          params: {
            id: data.id,
          },
        },
      );
    },
  },
};
</script>
